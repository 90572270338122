
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax';
import { Social } from '../../components';

import './FreeDayPage.css';

const FreeDayPage = () => {
  const { t } = useTranslation();
  const container = useRef<HTMLDivElement>(null);
  const headerTitleRef = useRef<HTMLDivElement>(null);
  const bodyTitleRef = useRef<HTMLDivElement>(null);
  const parallax = useRef<IParallax>(null!);

  const scrollTo = (offset: number) => {
    parallax.current.scrollTo(offset);
  }

  const goToRegisterSite = () => {
    window.open('https://lettersandnumbers.am/events/83cb755c-ee00-4411-a70d-2d2eef58b786');
  }

  const url = (name: string, wrap = false) =>
  `${wrap ? 'url(' : ''}/images/${name}${wrap ? ')' : ''}`

  useEffect(() => {
    console.log(url('waves-medium-blue3', true))
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('freeDay.title')}</title>
        <meta name="description" content={t('freeDay.description') ?? ''}/>
        <link rel="canonical" href="https://lettersandnumbers.am/promo/free-day" />
      </Helmet>

      <Parallax ref={parallax} pages={5}>
        
        <ParallaxLayer
          offset={0}
          speed={0}
          factor={5}
          style={{
            backgroundImage: url('waves-medium-blue3.svg', true),
            backgroundSize: 'cover',
          }}
        />

        <ParallaxLayer sticky={{ start: 0, end: 5 }} style={{ pointerEvents: 'none' }}>
          <header className="FreeDayPageHeader">
            <div className="FreeDayPageHeaderContent">
              <h1 ref={headerTitleRef} className="FreeDayPageHeaderTitle">FREE LAN DAY</h1>
            </div>
          </header>
        </ParallaxLayer>

        <ParallaxLayer
          offset={0.3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <button className="FreeDayPageNextBtn" onClick={() => scrollTo(1)}>👇</button>
        </ParallaxLayer>

        <ParallaxLayer 
          offset={1.3} 
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }} 
        >
          <button className="FreeDayPageNextBtn" onClick={() => scrollTo(2)}>👇</button>
        </ParallaxLayer>

        <ParallaxLayer 
          offset={2.3} 
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }} 
        >
          <button className="FreeDayPageNextBtn" onClick={() => scrollTo(3)}>👇</button>
        </ParallaxLayer>

        <ParallaxLayer 
          offset={3.3} 
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }} 
        >
          <button className="FreeDayPageNextBtn" onClick={() => scrollTo(4)}>👇</button>
        </ParallaxLayer>

        <ParallaxLayer sticky={{ start: 0.1, end: 5 }} speed={-0.3} style={{ pointerEvents: 'none' }}>
          <div className="FreeDayPageBody">
            <div className="FreeDayPageBodyContent">
              <h2 ref={bodyTitleRef} className="FreeDayPageBodyTitle">at Letters and Numbers</h2>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer className="FreeDayPageParallaxLayerCard1" sticky={{ start: 1, end: 3 }} style={{ pointerEvents: 'none' }}>
          <div className="FreeDayPageCard FreeDayPageCard FreeDayPageCardBig FreeDayPageCard1">
            <p className="FreeDayPageCardItem">FREE LAN DAY — это тестовый и бесплатный день в коворкинге Letters and Numbers 🎉</p>
            <p>Приглашаем вас провести продуктивный рабочий день в нашем пространстве 🏡</p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer className="FreeDayPageParallaxLayerCard2" offset={2} speed={1.5}  style={{ pointerEvents: 'none', zIndex: 100 }}>
          <div className="FreeDayPageCard FreeDayPageCard FreeDayPageCardSmall FreeDayPageCard2">
            <ul className="FreeDayPageList">
              <p className="FreeDayPageListTitle" style={{ marginBottom: '12px' }}>Доступные опции в FREE LAN DAY:</p>
              <li className="FreeDayPageListItem FreeDayPageListItem1" style={{ marginBottom: '4px' }}>Бесплатное посещение тихой и шумной зон коворкинга</li>
              <li className="FreeDayPageListItem FreeDayPageListItem2" style={{ marginBottom: '4px' }}>Высокоскоростной интернет до 200 мб/сек</li>
              <li className="FreeDayPageListItem FreeDayPageListItem3" style={{ marginBottom: '4px' }}>Посещение в любое время с 10:00 до 22:00</li>
              <li className="FreeDayPageListItem FreeDayPageListItem4" style={{ marginBottom: '4px' }}>Скидка 20% на напитки и еду из нашей кофейни</li>
              <li className="FreeDayPageListItem FreeDayPageListItem5" style={{ marginBottom: '4px' }}>Скидка 20% на покупку абонемента в коворкинг на неделю</li>
            </ul>
          </div>
        </ParallaxLayer>

        <ParallaxLayer className="FreeDayPageParallaxLayerCard3" offset={3} speed={1.5} onClick={() => scrollTo(4)}>
          <div className="FreeDayPageCard FreeDayPageCard FreeDayPageCardSmall FreeDayPageCard3">
            <ul className="FreeDayPageList">
              <p className="FreeDayPageListTitle" style={{ marginBottom: '12px' }}>Как попасть на FREE LAN DAY?</p>
              <li className="FreeDayPageListItem FreeDayPageListItem6" style={{ marginBottom: '4px' }}>Просто зарегистрируйтесь по форме на <a href="https://lettersandnumbers.am/events/83cb755c-ee00-4411-a70d-2d2eef58b786" target="_blank">сайте</a></li>
              <li className="FreeDayPageListItem" style={{ marginBottom: '4px' }}>Пожалуйста, оставьте свой телеграм или местный номер телефона для связи</li>
            </ul>
          </div>
        </ParallaxLayer>

        <ParallaxLayer
          className="FreeDayPageParallaxLayerCard4"
          offset={4}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            pointerEvents: 'auto'
          }}
        >
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '50%',
          }}>
            <div className="FreeDayPageCard FreeDayPageCardTextCenter FreeDayPageCardSmall FreeDayPageCard4" onClick={goToRegisterSite}>
              <p className="FreeDayPageCardItem">Зарегистрироваться 🎉</p>
            </div>

            <button className="FreeDayPageNextBtn" onClick={() => scrollTo(0)}>👆</button>

            <footer className="FreeDayPageFooter">
              <p className="FreeDayPageFooterTitle">Остались вопросы? Напишите нам  <Social/></p>
            </footer>
          </div>
        </ParallaxLayer>
      </Parallax>
    </>
  );
}
  
export default FreeDayPage;
  