import { useTranslation } from 'react-i18next';
import './CoworkingOptionsTable.css';

const CoworkingOptionsTable = () => {  
    const { t } = useTranslation();

    return (
        <div>
            <div className="CoworkingOptionsTable">
                <div className="PriceTable PriceTable_padding">
                    <div className="PriceTable__head">
                        <div className="PriceTable__col PriceTable__col_tech col-content_center">
                            <div className="PriceTable__head__row"><h2 className="head__title PriceTable__title">{t('coworking.options.title')}</h2></div>
                        </div>
                    </div>
                    <div className="PriceTable__body">
                        <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__col_first PriceTable__text-color_blue">
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col1.row1')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col1.row2')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col1.row3')}
                            </div>
                        </div>

                        <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue">
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col2.row1')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col2.row2')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col2.row3')}
                            </div>
                        </div>

                        <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue">
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col3.row1')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col3.row2')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col3.row3')}
                            </div>
                        </div>

                        <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue">
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col4.row1')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col4.row2')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col4.row3')}
                            </div>
                        </div>

                        <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__col_last PriceTable__text-color_blue">
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col5.row1')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col5.row2')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.options.col5.row3')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="CoworkingOptionsTable">
                <div className="PriceTable PriceTable_padding">
                    <div className="PriceTable__head">
                        <div className="PriceTable__col PriceTable__col_tech col-content_center">
                            <div className="PriceTable__head__row"><h2 className="head__title PriceTable__title">{t('coworking.meetingRoom.title')}</h2></div>
                        </div>
                    </div>
                    <div className="PriceTable__body">
                        <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__col_first PriceTable__text-color_blue">
                            <div className="PriceTable__col-title PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.meetingRoom.col1.title')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.meetingRoom.col1.row1')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.meetingRoom.col1.row2')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.meetingRoom.col1.row3')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.meetingRoom.col1.row4')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.meetingRoom.col1.row5')}
                            </div>
                        </div>

                        <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__col_last PriceTable__text-color_blue">
                            <div className="PriceTable__col-title PriceTable__row PriceTable__row_fixed-height">
                                {t('coworking.meetingRoom.col2.title')}
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                3500
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                5000
                            </div>
                            <div className="PriceTable__row PriceTable__row_fixed-height">
                                2000
                            </div>
                        </div>
                    </div>

                    {/* <div className="PriceTable__footer">
                        <p className="PriceTable__footer-desc">{t('coworking.meetingRoom.desc1')}</p>
                        <p className="PriceTable__footer-desc">{t('coworking.meetingRoom.desc2')}</p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default CoworkingOptionsTable;
