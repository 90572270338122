import { PropsWithChildren } from 'react';
import './Card.css';

interface CardProps {
    topContent?: () => JSX.Element;
    bottomContent?: () => JSX.Element;
}

const Card = ({ topContent, bottomContent }: PropsWithChildren<CardProps>) => {
    return (
        <div className="Card">
            {topContent ? topContent() : null}
            <div className="CardLine"></div>
            {bottomContent ? bottomContent() : null}
        </div>
    );
}

export default Card;
