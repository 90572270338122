import { PropsWithChildren } from 'react';
import { Card } from '../Card';
import './RateCard.css'

interface RateCardTopProps {
    iconPath: string;
    topTitle: string;
    topDesc: string;
}

const RateCardTop = ({ iconPath, topTitle, topDesc }: PropsWithChildren<RateCardTopProps>) => {
    return (
        <div className="RateCardTop">
            <img className="RateCardTopIcon" src={iconPath} />
            <p className="RateCardTopDesc">{topDesc}</p>
            <h1 className="RateCardTopTitle">{topTitle}</h1>
        </div>
    )
}

interface RateCardBottomProps {
    items: { iconPath: string, label: string, isActive: boolean }[];
}

const RateCardBottom = ({ items }: PropsWithChildren<RateCardBottomProps>) => {
    return (
        <ul className="RateCardBottom RateCardBottomList">
            {
                items.map((item, index) => {
                    return (
                        <li key={index + item.label} className={"RateCardBottomItem" + (!item.isActive ? " RateCardBottomItemNotActive" : "")}>
                            <img className="RateCardBottomIcon" src={item.iconPath} alt="check" />
                            <p className="RateCardBottomText">{item.label}</p>
                        </li>
                    )
                })
            }
        </ul>
    )
}

interface CardProps {
    iconPath: string;
    topTitle: string;
    topDesc: string;
    items?: { iconPath: string, label: string, isActive: boolean }[];
}

const RateCard = ({ iconPath, topTitle, topDesc, items = [] }: PropsWithChildren<CardProps>) => {
    return (
        <div className="RateCard">
            <Card 
                topContent={() => 
                    <RateCardTop
                        iconPath={iconPath}
                        topTitle={topTitle}
                        topDesc={topDesc}
                    />
                }
                bottomContent={() => 
                    <RateCardBottom items={items}/>
                }
                ></Card>
        </div>
    );
}

export default RateCard;
