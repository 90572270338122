import { useTranslation } from 'react-i18next';
import { RateCard } from '../RateCard';
import ClockIcon from '../../assets/icons/clock.png';
import LetterIcon from '../../assets/icons/letter.png';
import BriefcaseIcon from '../../assets/icons/briefcase.png';
import ProductiveIcon from '../../assets/icons/productive.png';
import CalendarIcon from '../../assets/icons/calendar.png';
import CheckIconPath from '../../assets/icons/check.svg';

import './PriceTable.css';

const PriceTable = () => {  
    const { t } = useTranslation();

    const items1Hours = [
        { iconPath: CheckIconPath, label: t('coworking.prices.services.wifi'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.period1Hour'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.meetingRoom'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffee'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.storage'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.fixedDesk'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.printScan'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.discount'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffeeSubscription'), isActive: false },
    ];

    const items4Hours = [
        { iconPath: CheckIconPath, label: t('coworking.prices.services.wifi'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.period4Hours'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.meetingRoom30Min'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffee'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.storage'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.fixedDesk'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.printScan'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.discount'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffeeSubscription'), isActive: false },
    ];

    const items1Day = [
        { iconPath: CheckIconPath, label: t('coworking.prices.services.wifi'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.period1Day'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.meetingRoom30Min'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffee'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.storage'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.fixedDesk'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.printScan'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.discount'), isActive: false },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffeeSubscription'), isActive: false },
    ];

    const items7Days = [
        { iconPath: CheckIconPath, label: t('coworking.prices.services.wifi'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.period15Days'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.meetingRoom2Hours'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffee'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.storage'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.fixedDesk'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.printScan10Pages'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.discount10'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffeeSubscription'), isActive: false },
    ];

    const items30Days = [
        { iconPath: CheckIconPath, label: t('coworking.prices.services.wifi'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.period45Days'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.meetingRoom10Hours'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffee'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.storage'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.fixedDesk'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.printScan10Pages'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.discount10'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffeeSubscription'), isActive: false },
    ];

    const itemsLanPlus = [
        { iconPath: CheckIconPath, label: t('coworking.prices.services.wifi'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.period60Days'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.meetingRoom20Hours'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffee'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.storage2Suitcases'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.fixedDesk'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.printScan100Pages'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.discount20'), isActive: true },
        { iconPath: CheckIconPath, label: t('coworking.prices.services.coffeeSubscription10')   , isActive: true },
    ];

    return (
        <div className="PriceTable">
            <div className="PriceTable__col PriceTable__col_tech col-content_center">
                <div className="PriceTable__head__row"><h2 className="head__title PriceTable__title">{t('coworking.prices.title')}</h2></div>
            </div>

            <div className="PriceTable-wrapper">                 
                <RateCard iconPath={ClockIcon} topTitle="1300 ֏" topDesc={t('coworking.prices.card1.desc')} items={items1Hours}/>
                <RateCard iconPath={LetterIcon} topTitle="3000 ֏" topDesc={t('coworking.prices.card2.desc')} items={items4Hours}/>
                <RateCard iconPath={BriefcaseIcon} topTitle="5000 ֏" topDesc={t('coworking.prices.card3.desc')} items={items1Day}/>
                <RateCard iconPath={ProductiveIcon} topTitle="25000 ֏" topDesc={t('coworking.prices.card4.desc')} items={items7Days}/>
                <RateCard iconPath={CalendarIcon} topTitle="75000 ֏" topDesc={t('coworking.prices.card5.desc')} items={items30Days}/>
                <RateCard iconPath={CalendarIcon} topTitle="120000 ֏" topDesc={t('coworking.prices.card6.desc')} items={itemsLanPlus}/>
            </div>
        </div>
    );
}
  
export default PriceTable;
  