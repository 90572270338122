import { useLocation } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { errorNotify, submitBookingNotify } from '../../utils';
import { Link } from '../../components';
import 'dayjs/locale/en';
import './BookingPage.css';

const BookingPage = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const getSource = () => {
      const queryParams = new URLSearchParams(location.search);
      return queryParams.get('source');
    }

    const validationSchema = yup.object({
      name: yup
        .string()
        .required('Требуется имя'),
      email: yup
        .string()
        .email('Введите действительный адрес электронной почты')
        .required('Требуется электронная почта'),
      phone: yup
        .number()
        .required('Требуется телефон'),
    });

    const formik = useFormik({
      initialValues: {
        name: '',
        email: '',
        phone: '',
        numberOfPersons: 1,
        telegram: '',
        comment: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        submitForm(values);
      },
    });

    const submitForm = async (values: any) => {
      try {
        await fetch(`${process.env.REACT_APP_BASE_URL}/api/booking`, {
          method: 'POST',
          body: JSON.stringify({
            name: values.name,
            email: values.email,
            phone: values.phone,
            numberOfPersons: String(values.numberOfPersons),
            telegram: values.telegram === undefined ? '' : `@${values.telegram}`,
            comment: values.comment,
            source: getSource()
          })
       });
        
        submitBookingNotify();

        formik.handleReset(() => {});
      } catch (err) {
        errorNotify();
        console.warn(err);
      }
    }

    const handleChangeNumberOfPersons = (value: React.ChangeEvent<any>) => {
      formik.handleChange(value)
    }

    return (
        <div className="BookingPage">
            <div className="BookingPageContent">
               <div className="BookingPageBlock">
                  <h1 className="BookingPageTitle">{t('booking.title')}</h1>
                  
                  <Trans i18nKey="booking.desk">
                    <p className="BookingPageDesc">
                      <strong></strong>
                      <strong></strong>
                      <br/>
                      <Link href="https://t.me/lan_yerevan" target="_blank" className="email-link"></Link>
                      <Link href="tel:+37494601303" target="_blank" alt="phone link" className="email-link"></Link>
                    </p>
                  </Trans>
               </div>
            
                <div className="BookingPageBlock">
                    <div className="BookingPageFormBlockWrapper">              
                        <div className="BookingPageFormWrapper">

                        <h1 className="BookingPageFormTitle">
                            {t('booking.form.title')}
                        </h1>

                        <form className="BookingPageForm" onSubmit={formik.handleSubmit}>    
                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                label={t('booking.form.fieldName')}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                
                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label={t('booking.form.fieldEmail')}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                
                            <TextField
                                fullWidth
                                id="phone"
                                name="phone"
                                label={t('booking.form.fieldPhone')}
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                
                            <div className="BookingPageFieldGroup">
                                <TextField
                                    fullWidth
                                    inputProps={{ min: 1, max: 10 }}
                                    maxRows={2}
                                    id="numberOfPersons"
                                    name="numberOfPersons"
                                    label={t('booking.form.fieldNumberOfPersons')}
                                    type="number"
                                    value={formik.values.numberOfPersons}
                                    onChange={handleChangeNumberOfPersons}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.numberOfPersons && Boolean(formik.errors.numberOfPersons)}
                                />
                        
                                <TextField
                                    fullWidth
                                    id="telegram"
                                    name="telegram"
                                    label={t('booking.form.fieldTelegram')}
                                    value={formik.values.telegram}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.telegram && Boolean(formik.errors.telegram)}
                                    helperText={t('booking.form.helperTelegram')}
                                />
                            </div>
                
                            <TextField
                                fullWidth
                                id="comment"
                                name="comment"
                                label={t('booking.form.fieldComment')}
                                value={formik.values.comment}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.comment && Boolean(formik.errors.comment)}
                                helperText={formik.touched.comment && formik.errors.comment}
                            />
                
                            <Button
                                className="BookingPageFormButton" 
                                color="primary" 
                                variant="contained" 
                                fullWidth
                                type="submit"
                            >
                              {t('booking.form.submitButton')}
                            </Button>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingPage;
