const en = {
    translation: {
        head: {
            title: 'Letters and numbers | Coworking Space | Coffee shop | Events | Yerevan',
            description: 'Letters and numbers - inspiring coworking space and coffee shop for work in the center of Yerevan.'
        },
        home: {
            title: 'Letters and numbers - inspiring coworking space and coffee shop for work in the center of Yerevan.'
        },
        nav: {
        home: 'home',
        coworking: 'coworking',
        coffeeshop: 'coffee shop',
        events: 'events',
        about: 'about us',
        contacts: 'contacts'
        },
        coworking: {
            title1: 'Coworking in a Unique Location',
            desc1: 'The uniqueness of our place lies in its ability to keep you hidden from prying eyes while being right in the heart of the city. This allows you to step out for errands and quickly return to work. Our garden and outdoor terraces are a great addition to the workspace. In any weather, you can take a stroll there with a cup of warming coffee and momentarily disconnect from the hustle and bustle.',
            title2: 'Amenities',
            desc2: 'The almost classic interior of the coworking space creates a business-like atmosphere, while its isolation from other rooms allows you to focus on what matters most. Our ergonomic desks are perfect for working and setting up with everything you need. The entire area is equipped with high-speed internet up to 100 Mbps. Energizing coffee, made from freshly roasted beans by local roasters, is already included in the coworking price and is unlimited for you.',
            title3: 'For Teams or Individual Work',
            desc3: 'Our space is not just a place to work comfortably but also a place to find like-minded people. Creating something new, sharing experiences, and growing – that’s what truly matters to us. We are always ready to help with any issues digital nomads may encounter in a new location. And for team work, we will provide the most convenient setup in our space.',
            title4: 'Pricing',
            prices: {
              title: 'Coworking Prices',
              card1: {
                desc: '1 hour'
              },
              card2: {
                desc: '4 hours'
              },
              card3: {
                desc: '1 day'
              },
              card4: {
                desc: '7 days'
              },
              card5: {
                desc: '30 days'
              },
              card6: {
                desc: 'LAN+'
              },
              services: {
                wifi: 'WIFI',
                period1Hour: 'Tariff period (1 hour)',
                period4Hours: 'Tariff period (4 hours)',
                period1Day: 'Tariff period (1 day)',
                period15Days: 'Tariff period (15 days)',
                period45Days: 'Tariff period (45 days)',
                period60Days: 'Tariff period (60 days)',
                meetingRoom: 'Meeting Room',
                meetingRoom30Min: 'Meeting Room (30 min)',
                meetingRoom2Hours: 'Meeting Room (2 hours)',
                meetingRoom10Hours: 'Meeting Room (10 hours)',
                meetingRoom20Hours: 'Meeting Room (20 hours)',
                coffee: 'Unlimited filter coffee, tea',
                storage: 'Storage locker',
                storage2Suitcases: 'Storage locker (2 suitcases)',
                fixedDesk: 'Fixed workspace',
                printScan: 'Printing, scanning',
                printScan10Pages: 'Printing, scanning (10 pages)',
                printScan100Pages: 'Printing, scanning (100 pages)',
                discount: 'Discount at LAN café and bar',
                discount10: 'Discount at LAN café and bar (10%)',
                discount20: 'Discount at LAN café and bar (20%)',
                coffeeSubscription: 'Coffee subscription',
                coffeeSubscription10: 'Coffee subscription (10 cups)',
            }
          },    
          meetingRoom: {
            title: 'Meeting Room (up to 6 people)',
            col1: {
              title: 'Options',
              row1: 'Tariff "1+1"',
              row2: 'Tariff "up to 6 people"',
              row3: 'Tariff for residents (additional hours beyond included ones)',
              row4: '* - discount for bookings of 5 hours or more',
              row5: '** - booking in 30-minute increments',
            },
            col2: {
              title: 'Price per 1 hour, ֏',
            },
          },                
          options: {
                title: 'COWORKING OPTIONS:',
                col1: {
                    title: '',
                    row1: 'working hours:\nweekdays\n10:00-22:00 \nweekends\n10:00-16:00',
                    row2: 'all types of\npayment: cash,\ncard, bank\naccount',
                    row3: 'special price for\nteams and\ncorporate clients',
                },
                col2: {
                    title: '',
                    row1: 'unlimited\nspecialty\nfilter-coffee or\ntea',
                    row2: 'private\nmeeting\nroom',
                    row3: 'community,\nevent\nvenue,\nnetworking',
                },
                col3: {
                    title: '',
                    row1: 'high-speed\ninternet\n200 mbit/s',
                    row2: 'air conditioning',
                    row3: 'a rich cultural\nprogram of events',
                },
                col4: {
                    title: '',
                    row1: 'convenient\nlocation\nin the city\ncentre',
                    row2: 'natural and\nartificial lighting',
                    row3: 'outdoor\nterraces for\nwork',
                },
                col5: {
                    title: '',
                    row1: 'private\ncourtyard with\ngarden',
                    row2: 'lunch zone,\nspecialty\ncoffee shop,\ncafé and bar',
                    row3: 'luggage\nstorage',
                },
            }
        },  
        coffeeshop: {
            title1: 'You can get coffee to go as well as to sit in the coffee house hall',
            title2: 'A few facts:',
            listItem1: 'We use freshly roasted beans.',
            listItem2: 'We work with the best coffee roasters and specialists in our industry.',
            listItem3: 'We have experienced baristas who are ready to make both classic drinks and to experiment based on your choice.',
            listItem4: 'We use purified water and professional water treatment equipment. Our water is free for everyone and in any quantity.',
            listItem5: 'We are pet-friendly.',
            title3: 'The basic coffee list:',
            coffeeItem1: 'Espresso',
            coffeeItem2: 'Americano',
            coffeeItem3: 'Cappuccino',
            coffeeItem4: 'Latte',
            coffeeItem5: 'Flat White',
            coffeeItem6: 'Raf',
            coffeeItem7: 'Cocoa',
            coffeeItem8: 'Filter',
        },
        events: {
            title: 'Events',
            text: 'Our space is suitable for the organization of private and public events, workshops and other activities. We are open to suggestions and will be happy to cooperate. We look forward to hearing from you at <0>info@creativecommunity.space</0> or direct to our <1>instagram</1>.'
        },
        about: {
            title: 'About us',
            text: '"Letters and Numbers" are a team of four people who met in Yerevan and didn`t know each other before we moved here. How did we get the idea to open a coffee shop? People in Armenia love and drink a lot of coffee, but there weren`t many places where you could drink delicious and high quality coffee. In the search for a suitable location, we decided to open a coffee shop in the format of "coworking + coffee shop", because in the city is clearly lack of places where you can sit with a laptop for personal or work matters. When discussing name options we remembered that in the Armenian alphabet letters can act as both letters and numbers at the same time, and all our communication is essentially a flow of numbers and letters. We are in a quite unique location: we are in the very center, but we are hidden from the roar of cars, we have our own small garden and swimming pool. And the location itself obliges: once it was the studio of architect Varazdat Arevshatyan, after more than 20 years the restaurant "At Gayane". We still have many regular visitors, including guests from distant countries. In general, we got a room with history, so we did not completely mop it up, but rethought and made an updated version of this place. We already have their own feature. For example, the "five o`clock". Nearby is the bell tower of the Church of St. Zoravor, which rings every 5 pm. You don`t even have to watch the clock.'
        },
        footer: {
            follow: 'FOLLOW US'
        },
        freeDay: {
            title: 'Letters and numbers | Free coworking | Coworking | Yerevan',
            description: ''
        },
        booking: {
            title: 'Order',
            desk: '<0>You can book your first visit to the coworking space for <0>2 hours for free</0> and a <1>coffee</1> of your choice using this form.<2></2> Or contact us: <3>telegram</3>, <4>phone</4></0>',
            form: {
                title: "Online registration",
                fieldName: "Name",
                fieldEmail: "Email",
                fieldPhone: "Phone",
                fieldNumberOfPersons: "Number of Persons",
                fieldTelegram: 'Telegram',
                helperTelegram: 'If you don\'t have Telegram, please leave a contact we can reach you through in the comments.',
                fieldDate: 'Date',
                fieldComment: 'Comment',
                submitButton: 'Submit'
            }
        }
    }
}

export default en;
